<template>
    <div class="mcontainer">
        <div class="breadcrumb-area py-0">
            <div class="breadcrumb">
                <ul class="m-0">
                    <li>
                        <router-link to="/app/group">Groups</router-link>
                    </li>
                    <li>
                        <router-link to="/app/group/categories">Categories</router-link>
                    </li>
                    <li class="active">
                        <a href="#">Edit Category </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="max-w-2xl m-auto shadow-md rounded-md bg-white mt-5">
            <form @submit.prevent="actionSubmit()">
                <div class="text-center border-b py-4">
                    <h3 class="font-bold text-lg"> Edit Category </h3>
                </div>
                <div class="p-7 space-y-5">
                    <div>
                        <label class="font-semibold">Category Name</label><br>
                        <input class="form-control" v-model="form.title" type="text" name="title" value="" autocomplete="off" required>
                    </div>
                    <div>
                        <label class="font-semibold text-base">Image</label><br>
                        <div class="image-uploader">
                            <input name="file" type="file" ref="preview" @change="onFileSelected($event, 'image')">
                            <span @click="$refs.preview.click()"><Icon icon="bi:cloud-upload" /></span>
                            <img :src="image ? image : $placeholder2" alt="Preview" class="w-80">
                        </div>
                    </div>
                </div>
                <!-- form footer -->
                <div class="border-t flex justify-center border-gray-100 p-6">
                    <button class="lg:w-1/3 h-9 lg:px-5 rounded-md bg-blue-600 text-white space-x-1.5 px-5" :class="disable_submit">
                        <span v-if="disable_submit == ''">Submit</span>
                        <b-spinner v-if="disable_submit == 'disabled'" label="Loading..." small />
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
    import { mapActions } from 'vuex'
    import { Icon } from '@iconify/vue2'

    export default {
        name: 'CreateGroup',
        components: {
            Icon
        },
        data () {
            return {
                form: {
                    title: '',
                    image: ''
                },
                image: '',
                loading: false,
                disable_submit: ''
            }
        },
        created () {
            this.loading = true
            this.show(this.$route.params.id)
                .then((data) => {
                    this.form.title = data.title
                    if (data.images) this.form.image = data.images
                    this.image = data.images
                })
                .finally(() => {
                    this.loading = false
                })
        },
        methods: {
            ...mapActions('groupCategories', ['update', 'show']),
            actionSubmit () {
                this.disable_submit = 'disabled'
                this.loading = true
                this.update({ id: this.$route.params.id, data: this.form })
                    .then(() => {
                        this.$router.push({ name: 'CategoryGroup' })
                    })
                    .finally(() => {
                        this.loading = false
                        this.disable_submit = ''
                    })
            },
            onFileSelected (e, target) {
                this.form[target] = e.target.files[0]
                this[target] = URL.createObjectURL(this.form[target])
            }
        }
    }
</script>
